'use client';

import { useProModal } from '@/hooks/use-pro-modal';
import { PREMIUM_PRICE_MONTH, PRO_PRICE_MONTH } from '@/constant';
import { CircleArrowRight } from 'lucide-react';
import { usePathname, useRouter } from 'next/navigation';
import React, {
  useState, useEffect, startTransition,
} from 'react';
import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from './ui/dialog';
import { Badge } from './ui/badge';
import { Card } from './ui/card';
import { Button } from './ui/button';
import Loading from './loading';

export const ProModal = () => {
  const [loading, setLoading] = useState(false);

  const router = useRouter();
  const proModal = useProModal();
  const pathname = usePathname();

  useEffect(() => {
    if (loading && pathname === '/subscription') {
      setLoading(false);
      proModal.onClose();
    }
  }, [loading, pathname, proModal]);

  const handleButtonClick = () => {
    setLoading(true);

    startTransition(() => {
      router.push('/subscription');
    });
  };
  return (
    <div>
      <Dialog open={proModal.isOpen} onOpenChange={proModal.onClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="flex justify-center items-center flex-col gap-y-4 pb-2">
              <div className="flex items-center gap-x-2 font-bold py-1">
                Upgrade to CookGPT
                <Badge variant="premium" className="text-sm py-1 uppercase">
                  Price Plan
                </Badge>
              </div>

            </DialogTitle>
            <DialogDescription className="text-center pt-2 space-y-2 text-zinc-900 font-medium">
              <Card className=" space-y-4 p-3 border-black/5 ">

                <div className="flex items-center px-4 justify-between p-1  bgc-color text-gray-200 rounded-lg  ">
                  <h3 className="text-lg font-medium">Premium</h3>
                  <p className=" text-lg">
                    $
                    {PREMIUM_PRICE_MONTH}
                    /month
                  </p>
                </div>
                <div className="flex items-center px-4 justify-between p-1  bg-gradient-to-r from-deep-blue to-red text-gray-200 rounded-lg  ">
                  <h3 className="text-lg font-medium">Professional</h3>
                  <p className=" text-lg">
                    $
                    {PRO_PRICE_MONTH}
                    /month
                  </p>
                </div>
              </Card>
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              className="w-full text-gray-100  bg-blue  hover:bg-[#0ea5e9] text-[15px] uppercase"
              onClick={handleButtonClick}
              disabled={loading}
            >
              {loading ? (
                <Loading loadingtext="Loading" />

              ) : (
                <>
                  Choose Your Plan
                  <CircleArrowRight className="w-6 h-5 ml-2" />
                </>
              )}

            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ProModal;
