/* eslint-disable import/prefer-default-export */

'use client';

import { useEffect } from 'react';
import { Crisp } from 'crisp-sdk-web';

export const CrispChat = () => {
  useEffect(() => {
    Crisp.configure('0cc0c95a-8bb7-49f2-8e96-5de0223cffa8');
  }, []);
  return null;
};
